import React from "react";
import SelectBoxItem from "src/components/form-items/FormSelectBox/SelectBoxItem";
import { OptionModel } from "@models/global";

interface SelectBoxItemsProps {
  value?: any;
  onChange?: (value: any) => void;
  options: OptionModel[];
  containerClass?: string;
}

const SelectBoxItems: React.FC<SelectBoxItemsProps> = ({
  value,
  onChange,
  options,
  containerClass,
}) => {
  const handleSelect = (selectedValue: any) => {
    onChange?.(selectedValue);
  };

  const wrapperClass = `flex gap-2 ${containerClass}`;

  return (
    <div className={wrapperClass}>
      {(options || []).map((item: any, index) => {
        return (
          <SelectBoxItem
            key={index}
            value={item.value}
            label={item.label}
            icon={item.icon}
            isSelected={value === item.value}
            onChange={handleSelect}
          />
        );
      })}
    </div>
  );
};

export default SelectBoxItems;
