import { cx } from "@/src/utils/all";
import React from "react";

interface FormSelectProps {
  label: string;
  value: string | number;
  isSelected?: boolean;
  icon?: string;
  onChange?: (value: any) => void;
}

const SelectBoxItem: React.FC<FormSelectProps> = ({
  label,
  value,
  isSelected,
  icon: Icon,
  onChange,
}) => {
  let wrapClass = cx(
    "flex items-center px-3 py-1 justify-center rounded-full cursor-pointer border bg-white focus:outline-none focus:ring-2 focus:ring-blue-500",
    isSelected
      ? "border-blue-500 text-blue-500"
      : "border-gray-300 text-gray-700 hover:border-gray-400",
  );
  return (
    <div className={wrapClass} onClick={() => onChange?.(value)}>
      {Icon ? <Icon /> : ""}
      <div className="label">{label}</div>
    </div>
  );
};

export default SelectBoxItem;
