import React from "react";
import { Form } from "antd";
import { OptionModel } from "@/src/models/global";
import SelectBoxItems from "../FormSelectBox/SelectBoxItems";

interface FormSelectButtonProps {
  name: string;
  options: OptionModel[];
  value?: string;
  onChange?: (value: string) => void;
}

const FormSelectButton: React.FC<FormSelectButtonProps> = ({
  name,
  options,
  value,
  onChange,
}) => {
  return (
    <Form.Item name={name} noStyle>
      <SelectBoxItems options={options} onChange={onChange} value={value} />
    </Form.Item>
  );
};

export default FormSelectButton;
