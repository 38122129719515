"use client";
import PostCardComponent from "@components/post/post-card.component";
import { useState, useEffect } from "react";
import { usePostStore } from "@stores/rootStoreProvider";
import { observer } from "mobx-react";
import LoadingComponent from "@/src/components/app-items/loading/loading.component";
import SuspenseWrap from "@components/app-items/suspense/suspense.component";
import { useTranslation } from "@/app/i18n/client";
import { PostModel } from "@/src/models/post/post.model";
import { POST_STATUS } from "@/src/lib/constants";

interface IProps {
  group: string;
  siteId?: string;
}

const PostSectionComponent = observer(({ group, siteId }: IProps) => {
  const { t } = useTranslation("common");
  const postStore = usePostStore();
  const [isLoading, setIsLoading] = useState(false);
  const [posts, setPosts] = useState<any>([]);
  const [filter] = useState<any>({
    page: 1,
    limit: 8,
    siteId,
    status: POST_STATUS.published,
  });

  useEffect(() => {
    handleSearch();
  }, []);

  const handleSearch = async () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    const pagedResult = (await postStore.filter(filter)) || [];
    setPosts(
      filter.page === 1
        ? pagedResult.results
        : posts.concat(pagedResult.results || []),
    );
    setIsLoading(false);
  };

  return isLoading ? (
    <LoadingComponent length={4} />
  ) : posts.length > 0 ? (
    <SuspenseWrap>
      <h1 className="flex justify-center text-3xl font-semibold text-slate-500">
        {t(`post.title_${group}`)}
      </h1>
      <div className="mt-10 grid grid-cols-2 gap-4 md:grid-cols-3 xl:grid-cols-4">
        {posts.map((post: PostModel, i: number) => {
          return (
            <PostCardComponent
              key={i}
              title={post.title}
              coverImage={post.image}
              date={post.createdAt}
              author={post.user}
              slug={post.slug}
              aspect="landscape"
            />
          );
        })}
      </div>
    </SuspenseWrap>
  ) : (
    <></>
  );
});

export default PostSectionComponent;
