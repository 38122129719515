"use client";

import React from "react";

export default function CategoryItem({ category, index }: any) {
  return (
    <div
      key={category.id}
      className={`category-item cursor-pointer px-2 text-base ${
        index === 0
          ? "active border-b border-slate-300 font-semibold text-slate-600"
          : "text-slate-400"
      }`}
      onClick={() => {
        const element = document.getElementById(`group-${category.id}`);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }}
    >
      {category.name}
    </div>
  );
}
