"use client";

import { useTranslation } from "@/app/i18n/client";
import { useEffect } from "react";

const DefaultLanguage = ({ langCode }: any) => {
  const { i18n } = useTranslation("en", "common");

  useEffect(() => {
    i18n.changeLanguage(langCode);
  }, []);

  return <></>;
};

export default DefaultLanguage;
