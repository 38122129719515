"use client";
import { cx } from "src/utils/all";
import { formatNumber as formatNumberKMB } from "react-tweet";
import BlurImage from "@/src/components/blur-image";
import { useOrderStore } from "@/src/stores/rootStoreProvider";
import { useTranslation } from "@/app/i18n/client";
import { Button } from "antd";
import { ShoppingCart } from "lucide-react";
import QuantityInput from "@/src/components/form-items/FormQuantity/QuantityInput";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import ButtonToggleIcon from "@/src/components/app-items/button/button-toggle-icon";
import { toast } from "sonner";

const MenuItemComponent = observer(({ data }: any) => {
  const { t } = useTranslation("common");
  const { name, image, price, unit, aspect, remaining } = data;
  const [cartItem, setCartItem] = useState<any>();
  const orderStore = useOrderStore();

  useEffect(() => {
    const cartItem = orderStore.editOrder.orderDetails?.find(
      (item) => item.productId === data.id,
    );

    if (cartItem) {
      setCartItem(cartItem);
    }
  }, [orderStore.editOrder]);

  const handleAddToCard = () => {
    if (!remaining || remaining < 0) {
      toast.error(t("order.productTemporaryOutOfStock"));
      return;
    }
    orderStore.showCartItemBottomSheet(data);
  };

  const updateCartItem = (quantity: any) => {
    const cartItemIndex = orderStore.editOrder.orderDetails?.findIndex(
      (item) => item.productId === data.id,
    );
    if (quantity <= 0) {
      if (cartItemIndex === -1) {
        return;
      }
      orderStore.removeOrderDetail(cartItemIndex);
      toast.success(
        t("order.updateCartQuantity", { name: name ?? "", quantity }),
      );
      return;
    }

    orderStore.addCartItem(data, { quantity });
    toast.success(
      t("order.updateCartQuantity", { name: name ?? "", quantity }),
    );
  };

  return (
    <div
      className={cx(
        "rounded-2xl border p-4",
        remaining > 0 ? "cursor-pointer" : "",
      )}
    >
      <div className="flex flex-none flex-row gap-4">
        <div className="flex justify-center">
          <div
            className={cx(
              "relative h-20 w-20 rounded-lg transition-all hover:scale-105 md:rounded-full",
              aspect === "landscape" ? "aspect-video" : "aspect-square",
            )}
          >
            <BlurImage
              src={image || "/empty-state.png"}
              alt={name}
              className="rounded-lg md:rounded-full"
              onClick={remaining > 0 ? handleAddToCard : undefined}
            />
            {/* Show add to cart when hover */}
            {remaining > 0 && (
              <div
                className="absolute inset-0 flex items-center justify-center rounded-full bg-black bg-opacity-50 opacity-0 transition-opacity hover:opacity-100"
                onClick={remaining > 0 ? handleAddToCard : undefined}
              >
                <Button
                  icon={<ShoppingCart size={16} />}
                  shape="circle"
                  className="border-none"
                ></Button>
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-auto flex-row justify-between">
          <div
            className="flex-auto"
            onClick={remaining > 0 ? handleAddToCard : undefined}
          >
            <h2 className="text-brand-primary flex justify-start text-lg font-semibold tracking-normal dark:text-white">
              <span className="link-underline link-underline-blue uppercase">
                {name}
              </span>
            </h2>

            <div className="flex justify-start text-base">
              {formatNumberKMB(price)} - {unit}
            </div>
            <div className="flex justify-start text-base">
              <span className="text-gray-500">
                {t("order.remainingItems")}{" "}
                <span className="text-gray-700">{remaining ?? "--"}</span>
              </span>
            </div>
          </div>
          <div className="flex flex-none flex-col justify-between">
            <div className="flex justify-center">
              <QuantityInput
                max={remaining ?? 0}
                value={cartItem?.quantity}
                onChange={updateCartItem}
              />
            </div>
            <div className="mt-2 flex justify-center">
              <ButtonToggleIcon
                label={t("btn.buyNow")}
                icon={<ShoppingCart size={16} />}
                onClick={handleAddToCard}
                type="primary"
                size="md"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default MenuItemComponent;
