"use client";
import { FileImageOutlined } from "@ant-design/icons";
import Link from "next/link";
import { cx } from "src/utils/all";
import ImageComponent from "../../app-items/image/image.component";
import { formatNumber as formatNumberKMB } from "react-tweet";
import { APP_ROUTE } from "@/src/lib/app-route";
import { BathIcon, BedIcon, RulerIcon } from "lucide-react";
import { useTranslation } from "@/app/i18n/client";

export default function UnitCardComponent({
  property: {
    id,
    name,
    group,
    project,
    photo,
    location,
    seller,
    slug,

    floorArea,
    numOfBedrooms,
    numOfBathrooms,
    price,
  },
  aspect,
}: {
  property: any;
  aspect?: string;
}) {
  const { t } = useTranslation("common");
  const route = APP_ROUTE.property.propertyDetail.replace("[slug]", slug ?? id);
  return (
    <div className="link-effect cursor-pointer rounded-md border dark:bg-gray-800">
      <div
        className={cx(
          "relative overflow-hidden rounded-t bg-gray-100 dark:bg-gray-800",
          aspect === "landscape" ? "aspect-video" : "aspect-[4/3]",
        )}
      >
        <Link href={route}>
          {photo ? (
            <ImageComponent
              url={photo}
              alt={`Căn hộ ${name}`}
              sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
              style={{ objectFit: "cover" }}
              className="transition-all hover:scale-105"
            />
          ) : (
            <span className="absolute left-1/2 top-1/2 h-16 w-16 -translate-x-1/2 -translate-y-1/2 text-gray-200">
              <FileImageOutlined />
            </span>
          )}
        </Link>
      </div>
      <div className="relative p-4">
        <div className="absolute -top-6 right-4 h-12 w-12 flex-shrink-0 rounded-full border bg-white drop-shadow-md">
          <ImageComponent
            url={seller?.avatar}
            alt={`${t("property.seller")} ${seller?.name ?? ""}`}
            className="rounded-full"
          />
        </div>

        <h2 className="text-brand-primary mt-2 text-lg font-semibold tracking-normal dark:text-white">
          <Link href={route}>
            <span className="link-underline link-underline-blue">{name}</span>
          </Link>
        </h2>

        <div className="address text-sm">
          {project?.name || location?.address}
        </div>

        <div className="amenity mt-3 flex justify-between text-xs text-gray-500">
          <div className="flex flex-col items-center justify-center ">
            <div className="icon">
              <RulerIcon strokeWidth={1} size={16} />
            </div>
            <div className="">{floorArea} m2</div>
          </div>
          <div className="flex flex-col items-center justify-center">
            <div className="icon">
              <BedIcon strokeWidth={1} size={16} />
            </div>
            <div className="">{numOfBedrooms}</div>
          </div>
          <div className="flex flex-col items-center justify-center">
            <div className="icon">
              <BathIcon strokeWidth={1} size={16} />
            </div>
            <div className="">{numOfBathrooms}</div>
          </div>
        </div>
      </div>
      <hr />
      <div className="p-4">
        <div className="flex items-center justify-between space-x-3 dark:text-gray-400">
          <div className="text-xs text-gray-500">{t(group)}</div>
          <span className="text-xs text-gray-300 dark:text-gray-600">
            &bull;
          </span>
          <div className="text-base font-semibold">
            {formatNumberKMB(price)} VNĐ
          </div>
        </div>
      </div>
    </div>
  );
}
