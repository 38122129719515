"use client";
import PropertyCardComponent from "./property-card.component";
import { useState, useEffect } from "react";
import { usePropertyStore } from "@stores/rootStoreProvider";
import { observer } from "mobx-react";
import LoadingComponent from "@/src/components/app-items/loading/loading.component";
import SuspenseWrap from "@components/app-items/suspense/suspense.component";
import { useTranslation } from "@/app/i18n/client";

const PropertyGroupComponent = observer(({ group }: any) => {
  const { t } = useTranslation("common");
  const propertyStore = usePropertyStore();
  const [isLoading, setIsLoading] = useState(false);
  const [properties, setProperties] = useState<any>([]);
  const [filter] = useState<any>({
    page: 1,
    limit: 12,
    group,
  });

  useEffect(() => {
    searchProperty();
  }, []);

  const searchProperty = async () => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    const pagedResult = (await propertyStore.filter(filter)) || [];
    setProperties(
      filter.page === 1
        ? pagedResult.results
        : properties.concat(pagedResult.results || []),
    );
    setIsLoading(false);
  };

  return (
    <SuspenseWrap>
      <h1 className="flex justify-center text-3xl font-semibold text-slate-500">
        {t(`property.title_${group}`)}
      </h1>
      {isLoading ? (
        <LoadingComponent length={4} />
      ) : (
        <div className="mt-10 grid grid-cols-2 gap-4 md:grid-cols-3 xl:grid-cols-4">
          {properties.map((unit: any, i: number) => {
            return <PropertyCardComponent property={unit} key={i} />;
          })}
        </div>
      )}
    </SuspenseWrap>
  );
});

export default PropertyGroupComponent;
